import React from 'react';
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import DetailsHead from '../components/News/NewsDetailsHead'
import DetailsBody from '../components/News/NewsDetailsBody'
import Breadcrumb from "../components/AreaGuideDetails/Breadcrumbs/CaseStudyDetailsBreadcrumbs"
import MoreNews from "../components/CaseStudies/MoreCaseStudies";
import FormBlock from '../components/Home/FormBlock/FormBlock';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";

const CaseStudyDetailsTemplate = (props) =>{
    
  const GQLPage = props.data?.glstrapi?.caseStudy;
  const OtherGuidesList = props.data?.glstrapi?.caseStudies;
  const GlobalModule = props.data?.glstrapi?.globalModule;

    return (
        <React.Fragment>
            <div className="wrapper news-full-details guide-details-template">
            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} image={GQLPage.Banner_Image ? GQLPage.Banner_Image.url : ''} />
            <Header />
            <Breadcrumb Pagename={GQLPage.Title} />
            <DetailsHead {...GQLPage} People={GQLPage.Contact[0]}/>
            <DetailsBody {...GQLPage} Add_More_Details={GQLPage.Add_Content} Speak_With={GQLPage.Contact} />
            <MoreNews guides={OtherGuidesList} />

            <FormBlock {...GlobalModule?.Case_Studies_Landing_Page}/>
            <Footer />
            </div>
        </React.Fragment>
    )
}
export default CaseStudyDetailsTemplate;



export const pageQuery = graphql`
  query caseQuery($slug: ID!) {
      glstrapi {
        caseStudy(id: $slug, publicationState: LIVE) {
            Title
            Meta_Title
            Meta_Description
            Date
            Address
            Banner_Image {
                url
                alternativeText
            }
            Video_URL
            Add_Content {
              ... on GLSTRAPI_ComponentModulesShowHelpModule {
                id
                Select_Module {
                  CTA_1_Label
                  CTA_2_Label
                  Content
                  Name
                  Show_Chart
                  Select_Form {
                    Select
                  }
                  CTA_1_Link {
                    id
                  }
                  CTA_2_Link {
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesContent {
                id
                Add_Content
              }
            }
            Contact {
              Name
              Designation
              Phone
              Image {
                url
                alternativeText
              }
            }
        }
        
        caseStudies(where: {id_ne: $slug}, limit: 3) {
          id
          Date
          Title
          Short_Description
          Video_URL
          URL
          Tile_Image {
            url
            alternativeText
          }
          Address
        }

        globalModule {
            Case_Studies_Landing_Page {
              Content
              Name
              Select_Form {
                Select
              }
              CTA_1_Label
              CTA_2_Label
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
            }
        }


      }
  }
`