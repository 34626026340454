import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import parse from "html-react-parser";
import PlayVideo from '../Play/CustomVideo';
import AdvisorImg from "../../images/area-guide/advisors.jpg";
import BookValuation from '../Home/BookValuation/BookValuation';
import "../AreaGuideDetails/VideoBlock/VideoBlock.scss"
import avatar from "../../images/avatar.png"
import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL, BRANCHES_PAGE_URL } from "../common/site/constants"

// markup
const NewsDetailsBody = (props) => {
  const [isPlay, setPlay] = useState(false);

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  return (
    <React.Fragment>
      <div className="video-block-wrap components--AreaGuideDetails--VideoBlock--VideoBlock">
        <Container>

          {props.Banner_Image &&
            <div className="banner-img">
              <div className="banner">
                <img src={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                {props.Video_URL &&
                  <PlayVideo url={props.Video_URL} />
                }
              </div>
            </div>
          }
          <Row>
            <Col md="8">
              <Row>

                <Col md="1">
                  <div className="block-content">
                    <ul className="social-line">
                      <li className="twitter-line">
                        <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                          <i className="icon-twitter-line"></i>
                        </TwitterShareButton>
                      </li>
                      <li className="facebook-line">
                        <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                          <i className="icon-facebook-line"></i>
                        </FacebookShareButton>
                      </li>
                      {/* <li className="linkedin-line">
                              <Link to="/"><i className="icon-linkedin-line"></i></Link>
                            </li> */}
                      {/* <li className="link-line">
                              <Link to="/"><i className="icon-link-line"></i></Link>
                            </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md="11">
                  <div className={`block-content ${props.Banner_Image ? '' : 'no-image'}`}>
                    <div className="block-text">
                      {props.Content &&
                        <>{parse(props.Content)}</>
                      }
                      {props.Add_More_Details?.map((item, index) => {
                        return (
                          <>
                            {item.Add_Content &&
                              <>{parse(item.Add_Content)}</>
                            }
                            {item.Select_Module &&
                              <div className="form-help-module">
                                <BookValuation {...item.Select_Module} />
                              </div>
                            }
                          </>
                        )
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="4" className={`block-content block-text ${props.Banner_Image ? '' : 'no-image'}`}>
              <div className="guide-details-right-block main-banner">
                <div className="first">
                  <form>
                    <div class="form-group">
                      <i class="icon-pin"></i>
                      <input placeholder="Enter your postcode or area" type="text" class="address form-control" />
                    </div>
                    <div className="btn-wrap row">
                      <Col lg={6} className="cta col-1">
                        <Link to={VALUATION_PAGE_URL.alias} className="btn btn-secondary">
                          <span>Selling or Letting?</span>Book a Valuation
                        </Link>
                      </Col>
                      <Col lg={6} className="cta col-2">
                        <Link to={FIND_A_PROPERTY_PAGE_URL.alias} className="btn">
                          <span>Buying or Renting?</span>Find a Property
                        </Link>
                      </Col>
                    </div>
                  </form>

                  {props.Authors && props.Authors.map((data, index) => {
                    return (
                      <div className="advisor-info">
                        <div className="advisor-img">
                          <img src={data.Image.url} alt={data.Image.alternativeText} />
                        </div>
                        <div className="advisor-contact">
                          <span className="advisor-name">{data.Name}</span>
                          <span className="advisor-designation">{data.Designation}</span>
                          <a href={`tel:${data.Phone}`} className="phone-number">
                            <i className="icon-phone"></i>
                            <span>{data.Phone}</span>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                  {
                    props.Authors && props.Authors.length === 0 &&
                    <div className="advisor-info">
                      <div className="advisor-img">
                        <img src={avatar} alt={`Author`} />
                      </div>
                      <div className="advisor-contact default">
                        <span className="advisor-name">Speak to us today</span>
                        <a href={`tel:0800854499`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>0800 854 499</span>
                        </a>
                      </div>
                    </div>
                  }
                </div>

                <div className="info-text">
                  <p><b>Not local to Ashford?</b> We’ve got 40+ branches across Kent and surrounding areas – <Link to={BRANCHES_PAGE_URL.alias}>find your local one.</Link></p>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default NewsDetailsBody