import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';

import { NewsDate } from "../common/site/functions"


function MoreCaseStudies(props) {

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news">
                <Container>
                    <Row className="head">
                        <Col lg={12}>
                            <h2>May also interest you...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {props.guides && props.guides.map((item, i) => {
                                return (
                                    <Link to={`../${item.URL}`}>
                                    <div className="guide">
                                        <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                                        <p className="title">{item.Title}</p>
                                        <p className="date">{item.Address}</p>
                                    </div>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreCaseStudies;
